import "../activities/Story";

import * as React from "react";
import { graphql, type PageProps } from "gatsby";

const StoryTemplate: React.FC<PageProps> = () => {
  return null;
};

export const query = graphql`
  query StoryTemplateQuery($id: String!) {
    prismicDaangnStory(id: { eq: $id }) {
      id
      uid
      data {
        article_type
        main_page_meta_title {
          text
        }
        main_page_meta_image {
          localFile {
            childImageSharp {
              gatsbyImageData
              fixed(width: 1200, height: 630, toFormat: PNG, quality: 90) {
                src
                width
                height
              }
            }
          }
        }
        main_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          alt
        }
        main_page_meta_description {
          text
        }
        main_title {
          text
        }
        appbar_share_link {
          text
        }
        appbar_share_link_logging_id {
          text
        }
        appbar_share_link_text {
          text
        }
        body {
          ...PrismicDaangnStoryDataBodyLikeAndShare_data
          ...PrismicDaangnStoryDataBodyTitleAndDescription_data
          ...PrismicDaangnStoryDataBodyGlobalProfileListSection_data
          ...PrismicDaangnStoryDataBodyVerticalService_data
          ...PrismicDaangnStoryDataBodyTitleAndDescription1_data
          ...PrismicDaangnStoryDataBodyIntroductionSection_data
          ...PrismicDaangnStoryDataBodyArticle_data
          ...PrismicDaangnStoryDataBodyCardDescription_data
          ...PrismicDaangnStoryDataBodyQuote_data
          ...PrismicDaangnStoryDataBodyRelationContents_data
        }
      }
    }
  }
`;

export default StoryTemplate;
